
import './labeled-counter.css';

import { AnimatedCounter } from  'react-animated-counter';

const LabeledCounter = ({ value, unit, label, fontSize = '26px', color = '#fff', incrementColor = '#fff', decrementColor = '#fff' }) => {

  return (
    <div className='labeled-counter'>
      <AnimatedCounter value={value}
        color={color}
        fontSize={fontSize}
        incrementColor={incrementColor} 
        decrementColor={decrementColor}
        includeDecimals={false}/>
      <p style={{lineHeight: fontSize, height: fontSize}}>
        {unit && <span className='unit' style={{fontSize: fontSize}}>{unit}</span>}
        {label && ' ' + label}
      </p>
    </div>
  );
};

export default LabeledCounter;
