import React, { useState } from 'react';
import './scrolled-input.css';

import { AnimatedCounter } from  'react-animated-counter';
import LabeledCounter from '../labeled-counter/labeled-counter';

const ScrolledInput = ({ label, orientation, value, suffix, onChange, increment = 1 }) => {
  const [inputValue, setInputValue] = useState(value);

  const handleIncrement = () => {
    const newValue = inputValue + increment;
    setInputValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleDecrement = () => {
    if (inputValue <= increment) return;
    const newValue = inputValue - increment;
    setInputValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className={`scrolled-input ${orientation}`}>
      <label className='label'>{label}</label>
      {orientation === 'horizontal' ? (
        <div className="horizontal-input">
          <div style={{color: '#63a1ff'}}>
            <LabeledCounter value={inputValue}
              unit={suffix}
              fontSize="26px"
              includeDecimals={false}/>
          </div>
          <div className='buttons'>
            <span className="button arrow arrow-up" onClick={handleIncrement}><span></span><span></span></span>
            <span className="button arrow arrow-down" onClick={handleDecrement}><span></span><span></span></span>
          </div>
        </div>
      ) : (
        <div className="vertical-input">
          <span className="button arrow arrow-up" onClick={handleIncrement}><span></span><span></span></span>
          <div style={{color: '#63a1ff'}}>
            <LabeledCounter value={inputValue}
              unit={suffix}
              fontSize="26px"
              includeDecimals={false}/>
          </div>
          <span className="button arrow arrow-down" onClick={handleDecrement}><span></span><span></span></span>
        </div>
      )}
    </div>
  );
};

export default ScrolledInput;
