import './App.css';
import { useState, useEffect } from 'react';
import { AnimatedCounter } from  'react-animated-counter';
import ScrolledInput from './components/scrolled-input/scrolled-input';
import LabeledCounter from './components/labeled-counter/labeled-counter';

function App() {
  const BOTTLE_WEIGHT = 32.6 / 1000 // kg
  // Input
  const [numberOfBottles, setNumberOfBottles] = useState(3);
  const [bottlePrice, setBottlePrice] = useState(150);
  const [years, setYears] = useState(3);

  // Result
  const [expenseInHuf, setExpenseInHuf] = useState(99999);
  const [plasticAmount, setPlasticAmount] = useState(256);
  const [bottleAmount, setBottleAmount] = useState(1536);


  const numberOfBottlesChanged = (value) => {
    setNumberOfBottles(value);
    calcResult();
  }

  const bottlePriceChanged = (value) => {
    setBottlePrice(value);
    calcResult();
  }

  const yearsChanged = (value) => {
    setYears(value);
    calcResult();
  }

  const calcResult = () => {    
    setExpenseInHuf(years * 365 * numberOfBottles * bottlePrice);
    setPlasticAmount(years * 365 * numberOfBottles * BOTTLE_WEIGHT);
    setBottleAmount(years * 365 * numberOfBottles);
  };

  useEffect(() => {
    calcResult();
  });

  return (
    <div className="App">
      <div className="flex-container">
   
        <div className="title-container">
          <div className='title-box'>
            <div className="title">PET palackos vizet iszol?</div>
            <div className="sub-title">Kalkuláld ki a jelenlegi költségeidet!</div>
          </div>
        </div>
        <div className="input-container">
          <ScrolledInput label="Napi víz fogyasztás" orientation="horizontal" value={numberOfBottles} suffix="palack" onChange={numberOfBottlesChanged} />
          <ScrolledInput label="Palackos víz ára" orientation="horizontal" value={bottlePrice} suffix="Ft" increment={10} onChange={bottlePriceChanged} />
        </div>
        <div className="result-container">
          <div className='year-input-container'>
            <ScrolledInput orientation="vertical" value={years} suffix="év" onChange={yearsChanged} />
          </div>
          <div style={{flexGrow: '1'}}>
            <div className='price-container border'>
              <LabeledCounter value={expenseInHuf}
                label={'költség'}
                unit={'Ft'}
                includeDecimals={false}/>
            </div>
            <div className='price-container'>
              <LabeledCounter value={plasticAmount}
                label={'műanyag hulladék'}
                unit={'kg'}
                includeDecimals={false}/>
            </div>
            <div className='price-container bootle-amount-container'>
              <LabeledCounter value={bottleAmount}
                unit={'db'}
                label={'palack'}
                fontSize="20px"
                includeDecimals={false}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
